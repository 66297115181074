import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { validatePhone } from "../common/helper";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { handleKeyDown, validatePassword } from "../../helper/validation";

function Signup() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("full_name", data?.full_name);
      formData.append("shop_name", data?.shop_name);
      formData.append("email", data?.email);
      formData.append("phone", data?.phone);
      formData.append("is_vendor", true);
      formData.append("password", data?.password);
      formData.append("password2", data?.password2);
      formData.append("address", data?.address);
      formData.append("status", "registered");
      formData.append("t_and_c", data?.tandc === "tandc" ? true : false);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}Admin-register/`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData.msg);
        localStorage.removeItem("token");

        setTimeout(() => {
          navigate("/vendor-verification", { state: { email: data?.email } });
        }, 2000);
      } else {
        const data = await response.json();
        toast.error(Object.values(data).join(", ") || "Something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };
  return (
    <div className="col-lg-6 d-flex justify-content-center">
      <div className="w-100 p-3 p-md-5 card border-0 shadow-sm">
        <form className="row g-1 p-3 p-md-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 text-center mb-5">
            <h1>Create your account</h1>
            <span>Free access to our dashboard.</span>
          </div>
          <div className="col-6">
            <div className="mb-2">
              <label className="form-label">Owner name</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="John Parker"
                {...register("full_name", { required: true })}
                onKeyDown={handleKeyDown} // Add event handler for keydown
              />
              {errors.full_name && (
                <span className="text-danger">Owner name is required</span>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="mb-2">
              <label className="form-label"> Shop name</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Shop name"
                {...register("shop_name", { required: true })}
                onKeyDown={handleKeyDown} // Add event handler for keydown
              />
              {errors.shop_name && (
                <span className="text-danger">Shop name is required</span>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <label className="form-label">Email address</label>
              <input
                type="text"
                id="email"
                className="form-control form-control-lg"
                placeholder="name@example.com"
                {...register("email", {
                  pattern: {
                    value:
                      /^(?!.*\.{2})(?!\.)[a-zA-Z0-9._%+-]+(?<!\.)@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\.)$/,
                    message: "Email is invalid",
                  },
                  required: "Email address is required",
                })}
              />
              {errors.email?.type === "required" && (
                <p role="alert" className="text-danger">
                  {errors.email.message}
                </p>
              )}
              {errors.email?.type === "pattern" && (
                <span className="text-danger">Email is invalid</span>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <label className="form-label">Phone number</label>
              <input
                type="number"
                className="form-control form-control-lg"
                placeholder="+91 1234567890"
                {...register("phone", {
                  validate: validatePhone,
                  required: true,
                })}
              />
              {errors.phone && errors.phone.type === "required" && (
                <span className="text-danger">Phone number is required.</span>
              )}
              {errors.phone && errors.phone.type === "validate" && (
                <span className="text-danger">
                  Please enter a valid phone number.
                </span>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <label className="form-label">Shop address</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Shop address"
                {...register("address", { required: true })}
              />
              {errors.address && (
                <span className="text-danger">Shop address is required</span>
              )}
            </div>
          </div>

          <div className="col-12">
            <div className="mb-2">
              <label className="form-label">Password</label>

              <div className="position-relative passwordShow">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control form-control-lg"
                  placeholder="***************"
                  {...register("password", { validate: validatePassword })}
                />
                {showPassword ? (
                  <FaEye onClick={togglePasswordVisibility} />
                ) : (
                  <FaEyeSlash onClick={togglePasswordVisibility} />
                )}
              </div>
              {errors.password && (
                <span className="text-danger">{errors.password.message}</span>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <label className="form-label">Confirm password</label>

              <div className="position-relative passwordShow">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control form-control-lg"
                  placeholder="***************"
                  {...register("password2", {
                    required: "Confirm password is required",
                    validate: (value) =>
                      value === watch("password") || "Passwords do not match",
                  })}
                />
                {showConfirmPassword ? (
                  <FaEye onClick={toggleConfirmPasswordVisibility} />
                ) : (
                  <FaEyeSlash onClick={toggleConfirmPasswordVisibility} />
                )}
              </div>
              {errors.password2 && (
                <span className="text-danger">{errors.password2.message}</span>
              )}
            </div>
          </div>

          <div className="col-12">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="tandc"
                id="flexCheckDefault"
                {...register("t_and_c", { required: true })}
              />

              <label className="form-check-label" htmlFor="flexCheckDefault">
                I accept the{" "}
                <Link
                  to="#!"
                  title="Terms and conditions"
                  className="text-secondary"
                >
                  Terms & Conditions
                </Link>
              </label>
            </div>
            {errors.t_and_c && (
              <span className="text-danger">
                Please accept Terms & Conditions
              </span>
            )}
          </div>
          <div className="col-12 text-center mt-4">
            <Button
              type="submit"
              className="btn btn-lg btn-block btn-light lift text-uppercase w-100 p-3"
            >
              SIGN UP
            </Button>
          </div>
          <div className="col-12 text-center mt-4">
            <span>
              Already have an account?{" "}
              <NavLink
                to={process.env.PUBLIC_URL + "/sign-in/vendor"}
                title="Sign in"
                className="text-secondary"
              >
                Sign in here
              </NavLink>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Signup;
