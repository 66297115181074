import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader1 from "../../components/common/PageHeader1";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FilterComponent } from "../../components/common/FilterComponent";
import ModalCom from "../../components/Modal/Modal";
import ConfimationModal from "../../components/Modal/Confirmation";
import InvoModel from "../../components/Modal/InvoModel";

function Status({ row, getQuestions }) {
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState("");
  function handleEdit(data) {
    setEditData(data);
    setShowModal(true);
  }

  function handleClose() {
    setShow("");
  }
  const handleDelete = async (id) => {
    setShow(id);
  };

  async function confirmHandler(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}involvement/${id}/`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      } else {
        getQuestions && getQuestions();
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div
        className="btn-group"
        role="group"
        aria-label="Basic outlined example"
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={() => handleEdit(row)}
        >
          <i className="icofont-edit text-success"></i>
        </button>
        <button
          type="button"
          className="btn btn-outline-secondary deleterow"
          onClick={() => handleDelete(row.id)}
        >
          <i className="icofont-ui-delete text-danger"></i>
        </button>
      </div>
      {show && (
        <ConfimationModal
          handleClose={handleClose}
          show={show}
          message="Are you sure you want to delete this question?"
          onConfirm={confirmHandler}
        />
      )}
      {showModal && (
        <InvoModel
          setShowModal={setShowModal}
          showModal={showModal}
          userType="taxrelief"
          getQuestions={getQuestions}
          editData={editData}
          setEditData={setEditData}
        />
      )}
    </>
  );
}

function Involvement() {
  const [table_row, setTable_row] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  async function getQuestions() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}involvement/`,
        {
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const results = await response.json();
      console.log(results, ">>>>>>>>>>>>>>>>>>");

      setTable_row(results);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      reset();
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getQuestions();
  }, []);

  // Filtering Category based on category name

  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = () => {
    return [
      {
        name: <span style={{ fontSize: "16px" }}>S.N.</span>,
        selector: (row) => row.sn,
        sortable: true,
        minWidth: "70px",
        maxWidth: "70px",
        compact: true,
      },
      {
        name: <span style={{ fontSize: "16px" }}>Name</span>,
        selector: (row) => row.name,
        sortable: true,
        minWidth: "380px",
        compact: true,
      },
      // {
      //   name: <span style={{ fontSize: "16px" }}>Category</span>,
      //   selector: (row) => row.category,
      //   sortable: true,
      //   minWidth: "100px",
      //   compact: true,
      // },
      {
        name: "ACTION",
        selector: (row) => {},
        sortable: true,
        cell: (row) => <Status row={row} getQuestions={getQuestions} />,
      },
    ];
  };

  let rows = [];

  if (table_row) {
    console.log(table_row, "rows");
    rows = table_row?.map((item, i) => {
      console.log(item, "item<<<<");
      return {
        sn: i + 1,
        id: item?.id,
        name: item?.name,
        status: item?.status,
      };
    });
  }

  return (
    <>
      {showModal && (
        <InvoModel
          setShowModal={setShowModal}
          showModal={showModal}
          userType="taxrelief"
          getQuestions={getQuestions}
        />
      )}
      <div className="body d-flex py-lg-3 py-md-2">
        <div className="container-xxl">
          <PageHeader1
            pagetitle="Involvement"
            modalbutton={() => {
              return (
                <div className="col-auto d-flex w-sm-100">
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="btn btn-primary btn-set-task w-sm-100"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>Add
                    Involvement
                  </button>
                </div>
              );
            }}
          />
          <div className="row clearfix g-3">
            <div className="col-sm-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div
                    id="myProjectTable_wrapper"
                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        {isLoading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ minHeight: "70vh" }}
                          >
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <DataTable
                            columns={columns()}
                            defaultSortField="title"
                            pagination
                            selectableRows={false}
                            className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                            highlightOnHover={true}
                            data={rows}
                            subHeader
                            paginationResetDefaultPage={resetPaginationToggle}
                            subHeaderComponent={subHeaderComponentMemo}
                            persistTableHead
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Involvement;
