import React from "react";
import "../index.css";

const BasicInfo = ({ data }) => {
  return (
    <div className="basic-info-container">
      <div className="basic-info-header">Personal Information</div>
      <div className="row basic-info-row">
        <div className="col-md-6">
          <div className="basic-info-label">Full Name:</div>
          <div className="basic-info-value">{data?.full_name}</div>
        </div>
      </div>
      <div>
        <div className="col-md-6">
          <div className="basic-info-label">Email:</div>
          <div className="basic-info-value">{data?.email}</div>
        </div>
      </div>
      <div className="row basic-info-row">
        <div className="col-md-6">
          <div className="basic-info-label">Phone:</div>
          <div className="basic-info-value">{data?.mobile_no}</div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
