import React, { useEffect } from "react";
import {
  Route,
  Routes as ReactRoutes,
  useNavigate,
  Outlet,
} from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import ProductGrid from "./Products/ProductGrid";
import ProductList from "./Product/ProductList";
import ProductOffer from "./Product/ProductOffer";
import ProductEdit from "./Products/ProductEdit";
import ProductDetail from "./Products/ProductDetail";
import ProductAdd from "./Products/ProductAdd";
import ShoppingCart from "./Products/ShoppingCart";
import Header from "../components/common/Header";
import CheckOut from "./Products/CheckOut";
import CategoriesList from "./Categories/CategoriesList";
import OrderList from "./Orders/OrderList";
import OrderDetail from "./Orders/OrderDetail";
import OrderInvoice from "./Orders/OrderInvoice";
import CustomerDetail from "./Customers/VendorDetails";
import CategoriesEdit from "./Categories/CategoriesEdit";
import CategoriesAdd from "./Categories/CategoriesAdd";
import BuyerList from "./Customers/Buyer";
import VendorList from "./Customers/Vendor";
import CategoryList from "./Product/Category";
import SubCategoryList from "./Product/SubCategory";
import AddDynamicForm from "./Product/AddDynamicForm";
import ProductFormList from "./Product/ProductFormList";
import ProductDetails from "./Product/ProductDetails";
import FAQ from "./Help_Support/FAQ";
import HelpSupport from "./Help_Support/HelpSupport";
import UserReport from "./Help_Support/UserReport";
import BuyerDetails from "./Customers/BuyerProfile";
import VendorDetails from "./Customers/VendorDetails";
import UserReportDetails from "./Help_Support/UserReportDetails";
import ProductOfferDetails from "./Product/ProductOfferDetails";
import SingleProductDetails from "./Product/SingleProductDetails";
import Chat from "./App/Chat";

function MainIndex(props) {
  const { activekey } = props;
  return (
    <div className="main px-lg-4 px-md-4">
      {activekey === "/chat" ? "" : <Header />}
      <div className="body d-flex pb-3">
        {/* <ReactRoutes>
          <Route
            exact
            path={process.env.PUBLIC_URL + "/"}
            element={<Dashboard />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/chat"}
            element={<Chat />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/dashboard"}
            element={<Dashboard />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/product-grid"}
            element={<ProductGrid />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/product-list"}
            element={<ProductList />}
          />

          <Route
            exact
            path={process.env.PUBLIC_URL + "/product-offer"}
            element={<ProductOffer />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/product-edit"}
            element={<ProductEdit />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/single-product-details"}
            element={<SingleProductDetails />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/product-details"}
            element={<ProductDetails />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/user-report-details"}
            element={<UserReportDetails />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/product-offer-details"}
            element={<ProductOfferDetails />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/product-details/:id"}
            element={<ProductDetails />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/productform-list"}
            element={<ProductFormList />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/product/add-form"}
            element={<AddDynamicForm type="add" />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/product/edit-form/:id"}
            element={<AddDynamicForm type="edit" />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/product/view-form/:id"}
            element={<AddDynamicForm type="view" />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/faq"}
            element={<FAQ />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/issues"}
            element={<HelpSupport />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/user-report"}
            element={<UserReport />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/product-Add"}
            element={<ProductAdd />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/shopping-cart"}
            element={<ShoppingCart />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/check-out"}
            element={<CheckOut />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/categories-list"}
            element={<CategoriesList />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/categories-edit"}
            element={<CategoriesEdit />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/categories-add"}
            element={<CategoriesAdd />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/order-list"}
            element={<OrderList />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/order-detail"}
            element={<OrderDetail />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/order-invoice"}
            element={<OrderInvoice />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/buyer-list"}
            element={<BuyerList />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/buyer-details"}
            element={<BuyerDetails />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/vendor-list"}
            element={<VendorList />}
          />

          <Route
            exact
            path={process.env.PUBLIC_URL + "/vendor-details"}
            element={<VendorDetails />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/category-list"}
            element={<CategoryList />}
          />
          <Route
            exact
            path={process.env.PUBLIC_URL + "/subcategory-list"}
            element={<SubCategoryList />}
          />
        </ReactRoutes> */}
        <Outlet />
      </div>
    </div>
  );
}
export default MainIndex;
