export const passwordStrengthRegex =
  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*()_+-=])/;
export const minPasswordLength = 8; // Minimum password length

export const validatePassword = (value) => {
  if (!value) {
    return "Password is required";
  }
  if (value.length < minPasswordLength) {
    return `Password must be at least ${minPasswordLength} characters long.`;
  }
  if (!passwordStrengthRegex.test(value)) {
    return "Password must be strong: at least 8 characters, including a lowercase, uppercase, number, and special character.";
  }
  return undefined; // No validation errors
};

export const handleKeyDown = (event) => {
  const allowedChars = /^[a-zA-Z ]+$/; // Regular expression for alphabets
  if (!allowedChars.test(event.key)) {
    event.preventDefault(); // Prevent non-alphabet characters from being entered
  }
};

// for email
// export const validateEmail = (value) => {
//   if (!value) {
//     return "Email is required.";
//   }

//     const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

//   if (!emailRegex.test(value)) {
//     return "Please enter a valid email address.";
//   }

//   if (value.includes('..')) {
//     return "Email cannot contain consecutive dots.";
//   }

//   return undefined;
// };
