// src/components/PublicRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import AuthIndex from "./screens/AuthIndex";

const PublicRoute = ({ isAuthenticated }) => {
  return localStorage.getItem("token") ? (
    <Navigate to="/users" replace />
  ) : (
    <div id="ebazar-layout" className="theme-blue">
      <AuthIndex />
    </div>
  );
};

export default PublicRoute;
