// Custom validation Function For email
export const validateEmail = (email) => {
  // Email Validation Using a Regular Expression in this ways
  const emailRegex =
    /^(?!.*\.{2})(?!\.)[a-zA-Z0-9._%+-]+(?<!\.)@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\.)$/;
  return emailRegex.test(email) || "Invalid email address";
};

// export const validatePhone = (phone) => {
//   const isNumeric = /^\d+$/.test(phone);  // Check if phone contains only digits
//   return isNumeric && phone.length >= 10;  // Ensure length is at least 10
// };

export const validatePhone = (value) => {
  const phoneNumber = value.replace(/\D/g, "");
  return phoneNumber.length === 10;
};
