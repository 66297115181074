import React from "react";
import "./questionnaire.css";

const FinanceForm = ({ qus }) => {
  return (
    <div className="container mt-4">
      <h1 className="h5 mb-4">Let's understand your finance!</h1>
      <div className="answer p-3 rounded mb-3">
        <strong>Annual Turnover &nbsp;:</strong> &nbsp; £&nbsp;
        {qus?.annual_turnover}
      </div>
      <div className="answer p-3 rounded mb-3">
        <strong>Total Finance Amount &nbsp;:</strong> &nbsp; £&nbsp;
        {qus?.total_finance_amount}
      </div>
      <div className="answer p-3 rounded mb-3">
        <strong>Reason For Finance&nbsp;:</strong> &nbsp;
        {qus?.reasonforfinance}
      </div>
    </div>
  );
};

export default FinanceForm;
