import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

const WorkSendModel = ({ UserData }) => {
  const [formData, setFormData] = useState({
    year: "2024-2025",
    email: UserData?.email,
  });

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("user", UserData?.id);
    formDataToSend.append("year", "2024-2025");
    formDataToSend.append("email", formData?.email);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}worksend/`,
        {
          method: "POST",
          body: formDataToSend,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      toast.success("workbook sent successfully");
      if (data) {
        handleClose();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Send WorkBook
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send Work Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formYear">
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={formData?.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </Form.Group>
            </Form.Group>

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Send Data
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WorkSendModel;
