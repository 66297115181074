import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Modal, Form, Button } from "react-bootstrap";

function ModalCom({
  showModal,
  setShowModal,
  userType,
  getQuestions,
  editData = null,
  setEditData = null,
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      inputFields: [{ start: "", end: "" }],
    },
  });
  const [loading, setLoading] = useState(false);
  const [qusType, setQusType] = useState(editData?.responseType);
  const [resType, setResType] = useState();
  const [qusStatus, setQusStatus] = useState(editData?.status);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "inputFields",
  });

  const handleCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (editData) {
      setValue("question", editData?.question);
      setValue("category", editData?.category);
      setValue("responseType", editData?.responseType);
      setValue("questionOrder", editData?.order);
      setValue("inputFields", editData?.startagainrange);
      setValue("status", editData?.status);
      setValue("choice1", editData?.choice1);
      setValue("choice2", editData?.choice2);
      setValue("choice3", editData?.choice3);
      setValue("choice4", editData?.choice4);
    }
  }, [editData, setValue]);

  const submitHandler = async (data) => {
    let path = editData ? `question/${editData?.id}/` : "question/";
    let method = editData ? "PATCH" : "POST";
    const formData = new FormData();
    formData.append("question", data?.question || "");
    formData.append("user_type", userType || "");
    formData.append("category", data?.category || "");
    formData.append("responseType", data?.responseType || "");
    formData.append("order", data?.questionOrder || "");
    formData.append("status", data?.status || "");
    formData.append("startagainrange", JSON.stringify(data?.inputFields) || "");
    formData.append("question_type", data?.qustype || "");
    formData.append("partno", data?.questionpart || "");
    formData.append("choice1", data?.choice1 || "");
    formData.append("choice2", data?.choice2 || "");
    formData.append("choice3", data?.choice3 || "");
    formData.append("choice4", data?.choice4 || "");
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASEURL}${path}`, {
        method,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      } else {
        getQuestions && getQuestions();
        setEditData && setEditData(null);
        setShowModal(false);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleAddFields = () => {
    append({ start: "", end: "" });
  };

  const handleRemoveFields = (index) => {
    remove(index);
  };

  return (
    <Modal show={showModal} onHide={handleCancel} centered backdrop="static">
      <Modal.Title className="bg-dark pt-2 pb-1">
        <h5 className="text-center text-light">
          {editData ? "Update Question" : "Add Question"}
        </h5>
      </Modal.Title>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formQuestion">
            <Form.Label>Question</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your question"
              className="mb-3 mw-100"
              {...register("question", { required: true })}
            />
            {errors?.question?.type === "required" && (
              <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                This field is required
              </p>
            )}
          </Form.Group>

          <Form.Group controlId="formCategory">
            <Form.Label>Category</Form.Label>
            <Form.Select
              type="text"
              placeholder="Select category"
              className="mb-3"
              {...register("category", { required: true })}
            >
              {userType === "taxrelief"
                ? [
                    "Discovery Call Questionnaire",
                    "Technical Call Questionnaire",
                    "Project Questionnaire",
                  ].map((item, i) => (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  ))
                : ["Discovery Call Questionnaire", "Project Questionnaire"].map(
                    (item, i) => (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    )
                  )}
            </Form.Select>
            {errors?.category?.type === "required" && (
              <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                This field is required
              </p>
            )}
          </Form.Group>

          <Form.Group controlId="formResponseType">
            <Form.Label>Response Type</Form.Label>
            <Form.Select
              type="text"
              placeholder="Select response type"
              className="mb-3"
              {...register("responseType", { required: true })}
              onChange={(e) => setQusType(e.target.value)}
            >
              {["Text/Audio", "Yes/No", "Mcq"].map((item, i) => (
                <option value={item} key={i}>
                  {item}
                </option>
              ))}
            </Form.Select>
            {errors?.responseType?.type === "required" && (
              <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                This field is required
              </p>
            )}
          </Form.Group>

          {qusType === "Mcq" && (
            <>
              <Form.Group controlId="formChoice1">
                <Form.Label>Choice 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Choice 1"
                  className="mb-3 mw-100"
                  {...register("choice1", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="formChoice2">
                <Form.Label>Choice 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Choice 2"
                  className="mb-3 mw-100"
                  {...register("choice2", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="formChoice3">
                <Form.Label>Choice 3</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Choice 3"
                  className="mb-3 mw-100"
                  {...register("choice3", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="formChoice4">
                <Form.Label>Choice 4</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Choice 4"
                  className="mb-3 mw-100"
                  {...register("choice4", { required: true })}
                />
              </Form.Group>
            </>
          )}

          <Form.Group controlId="formQusType">
            <Form.Label>Question Type</Form.Label>
            <Form.Select
              type="text"
              placeholder="Select question type"
              className="mb-3"
              {...register("qustype", { required: true })}
              onChange={(e) => setResType(e.target.value)}
            >
              {["Single", "Part"].map((item, i) => (
                <option value={item} key={i}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {resType === "Part" && (
            <Form.Group controlId="formQuestionPart">
              <Form.Label>Question Part No</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Question Part No"
                className="mb-3 mw-100"
                {...register("questionpart", { required: true })}
              />
              {errors?.questionpart?.type === "required" && (
                <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                  This field is required
                </p>
              )}
            </Form.Group>
          )}

          <Form.Group controlId="formQuestionOrder">
            <Form.Label>Question Order</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter question order"
              className="mb-3 mw-100"
              {...register("questionOrder", { required: true })}
            />
            {errors?.questionOrder?.type === "required" && (
              <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                This field is required
              </p>
            )}
          </Form.Group>

          <Form.Group controlId="formStatus">
            <Form.Label>Status</Form.Label>
            <Form.Select
              type="text"
              placeholder="Select status"
              className="mb-3"
              {...register("status", { required: true })}
              onChange={(e) => setQusStatus(e.target.value)}
            >
              {["Continue", "Exit", "Startagain"].map((item, i) => (
                <option value={item} key={i}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {qusStatus === "Startagain" && (
            <div>
              {fields.map((field, index) => (
                <div key={field.id} className="row align-items-center mb-6">
                  <div className="col-5">
                    <Form.Group controlId={`formQuestionStart${index}`}>
                      <Form.Label>Start</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter start value"
                        className="mb-3 mw-100"
                        {...register(`inputFields.${index}.start`, {
                          required: true,
                        })}
                      />
                      {errors?.inputFields?.[index]?.start?.type ===
                        "required" && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "14px",
                            margin: "0",
                          }}
                        >
                          This field is required
                        </p>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-5">
                    <Form.Group controlId={`formQuestionEnd${index}`}>
                      <Form.Label>End</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter end value"
                        className="mb-3 mw-100"
                        {...register(`inputFields.${index}.end`, {
                          required: true,
                        })}
                      />
                      {errors?.inputFields?.[index]?.end?.type ===
                        "required" && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "14px",
                            margin: "0",
                          }}
                        >
                          This field is required
                        </p>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-2 mb-2">
                    {index === fields.length - 1 && (
                      <Button
                        variant="primary"
                        className="mt-2"
                        onClick={handleAddFields}
                      >
                        +
                      </Button>
                    )}

                    {fields.length > 1 && (
                      <Button
                        variant="danger"
                        onClick={() => handleRemoveFields(index)}
                      >
                        -
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit(submitHandler)}>
          {loading ? "Please wait..." : "Save Changes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCom;
