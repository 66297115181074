import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "../../assets/images/verify.svg";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import OTPInput from "react-otp-input";

function VendorVerification() {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [timer, setTimer] = useState(30); // Set initial timer value to 30 seconds
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      setCanResend(false);
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setCanResend(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleResendOTP = async () => {
    setTimer(30); // Reset timer to 30 seconds
    // Implement your OTP resend logic here (e.g., API call)
    console.log("OTP resent");
    try {
      const formData = new FormData();
      formData.append("email", location?.state?.email);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}api/login_signup/ResendOtp/`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData?.msg || "OTP Verification successful!");
      } else {
        const data = await response.json();

        toast.error(Object.values(data).join(", ") || "Something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("otp", data.otp);
      formData.append("email", location?.state?.email);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}api/login_signup/vendor-otp-verify/`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData?.msg || "OTP Verification successful!");
        localStorage.removeItem("token");

        setTimeout(() => {
          // navigate("/sign-in/vendor");
          navigate("/documents", { state: responseData?.data?.id });
        }, 2000);
      } else {
        const data = await response.json();
        if (data?.error === "Invalid OTP") {
          toast.error(data?.error);
          return;
        }
        toast.warn(Object.values(data)[0] || "Something went wrong");

        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };
  return (
    <div
      className="w-100 p-3 p-md-5 card border-0 shadow-sm"
      style={{ maxWidth: "32rem" }}
    >
      <form className="row g-1 p-3 p-md-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12 text-center mb-5">
          <img src={Images} className="w240 mb-4" alt="" />
          <h1>Verification</h1>
          <span>
            We sent a verification code to your email. Enter the code from the
            email in the field below.
          </span>
        </div>
        <div className="OTPdESIGN">
          {" "}
          <Controller
            name="otp"
            control={control}
            defaultValue=""
            rules={{
              validate: (value) =>
                /^[0-9]{4}$/.test(value) || "Please enter OTP",
            }}
            render={({ field: { onChange, value } }) => (
              <OTPInput
                value={value}
                onChange={onChange}
                numInputs={4}
                shouldAutoFocus
                isInputNum
                renderInput={(props) => (
                  <input
                    {...props}
                    className="form-control form-control-lg text-center"
                    type="number"
                    pattern="\d*"
                  />
                )}
              />
            )}
          />
          {/* <OTPInput
            value={otp}
            onChange={setOTP}
            numInputs={4}
            renderInput={(props) => (
              <input
                {...props}
                className="form-control form-control-lg text-center"
              />
            )}
          /> */}
          {errors.otp && (
            <span className="text-danger">{errors.otp.message}</span>
          )}
        </div>
        <div className="col-12 text-center mt-4">
          <Button
            type="submit"
            className="btn btn-lg btn-block btn-light lift text-uppercase w-100 p-3"
          >
            Verify my account
          </Button>
        </div>
        <div className="col-12 text-center mt-4">
          <span>
            Haven't received it?{" "}
            <button
              type="button"
              className="text-secondary"
              onClick={handleResendOTP}
              disabled={!canResend}
            >
              Resend OTP {!canResend && ` in ${timer}s`}
            </button>
          </span>
        </div>
      </form>
    </div>
  );
}
export default VendorVerification;
