import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import Signup from "./components/Auth/Signup";
import DocumentVerification from "./components/Auth/DocumentVerification";
import ResetPassword from "./components/Auth/ResetPaasword";
import Verification from "./components/Auth/Verification";
import VendorVerification from "./components/Auth/VendorVerification";
import OTP from "./components/Auth/OTP";
import Document from "./components/Auth/Document";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Page404 from "./components/Auth/Page404";
import SignIn from "./components/Auth/SignIn";
import PublicRoute from "./PublicRoutes";
import { logout } from "./helper/logout";
import TaxReliefUsers from "./screens/TaxReliefUsers";
import QuestionCreations from "./screens/QuestionCreationUser";
import BusinessFinance from "./screens/Businessess";
import ViewTaxReliefUser from "./screens/TaxReliefUsers/view-user";
import EditTaxReliefUser from "./screens/TaxReliefUsers/edit-user";
import ViewBusinessUser from "./screens/Businessess/view-user";
import EditBusinessUser from "./screens/Businessess/edit-user";
import BusinessQuestionCreation from "./screens/QuestionCreationBusiness";
import Signature from "./screens/TaxReliefUsers/components/Signature";
import WorkBook from "../src/screens/workbookglobal/view-user";
import Involvement from "./screens/Involvement";

const App = () => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("token");

  useEffect(() => {
    const events = ["mousemove", "keydown", "scroll"];
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        logout();
        navigate(`/sign-in`, { replace: true });
      }, 3600000);
    };

    const handleActivity = () => {
      resetTimer();
    };

    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });
    resetTimer();
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
      clearTimeout(logoutTimer);
    };
  }, []);

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            allowedRoles={["admin"]}
          />
        }
      >
        <Route path="/" element={<Navigate to={"/users"} />} />

        <Route path="/users">
          <Route path="" element={<TaxReliefUsers />} />
          <Route path="edit-user" element={<EditTaxReliefUser />} />
          <Route path="view-user" element={<ViewTaxReliefUser />} />
        </Route>
        <Route
          path="/taxrelief-user-questions"
          element={<QuestionCreations />}
        />
        <Route
          path="/business-user-questions"
          element={<BusinessQuestionCreation />}
        />
        <Route path="/business">
          <Route path="" element={<BusinessFinance />} />
          <Route path="edit-user" element={<EditBusinessUser />} />
          <Route path="view-user" element={<ViewBusinessUser />} />
        </Route>
        <Route path="/involvement">
          <Route path="" element={<Involvement />} />
        </Route>
      </Route>

      {/* Auth routes */}
      <Route element={<PublicRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route
          path="/documents-verification"
          element={<DocumentVerification />}
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/documents" element={<Document />} />
        <Route path="/vendor-verification" element={<VendorVerification />} />
        <Route path="/otp-verification" element={<OTP />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/page-404" element={<Page404 />} />
      </Route>
      <Route exact path="/signature/:userid/:loeid" element={<Signature />} />
      <Route exact path="/workbook/:userid/:year" element={<WorkBook />} />
      {/* Not Authorized Route */}
      {/* <Route path="/not-authorized" element={<NotAuthorized />} /> */}
    </Routes>
  );
};

export default App;
