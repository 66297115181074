import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import editIcon from "../../assets/images/editIcon.svg";
import viewIcon from "../../assets/images/viewIcon.svg";
import DeleteModal from "../../components/Modal/DeleteModel";

const StatusButton = (props) => {
  let { params, catData, setApiCallStatus } = props;
  const subModuleRoles = "";
  // const { subModuleRoles } = useSelector((state) => state.BranchReducer);
  const [apiMessage, setApiMessage] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setApiMessage("");
    setShow(true);
  };
  console.log(props, "propspropsprops");
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate("/business/edit-user", { state: params });
  };

  const handleforView = () => {
    navigate("/business/view-user", { state: params });
  };

  const canEditCategory = true;
  const canDeleteCategory = true;
  const canViewCategory = true;

  return (
    <>
      <div>
        <ul className={`list-inline`}>
          {canEditCategory && (
            <li className="list-inline-item">
              <button
                className={`btn`}
                title="Edit"
                onClick={() => {
                  handleEditClick();
                }}
              >
                <img src={editIcon} alt="edit" />
              </button>
            </li>
          )}

          {canViewCategory && (
            <li className="list-inline-item">
              <button
                type="button"
                className={`btn`}
                title="view"
                onClick={handleforView}
              >
                <img src={viewIcon} alt="view" />
              </button>
            </li>
          )}

          {canDeleteCategory && (
            <DeleteModal id={props?.id} setApiCallStatus={setApiCallStatus} />
          )}
        </ul>
      </div>

      {/* <DeletePopup
        show={show}
        handleClose={handleClose}
        deleteApi={deletePermotion}
        apiMessage={apiMessage}
      /> */}
    </>
  );
};

export default StatusButton;
