import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

function StatusFinalDocument({ UserData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      // formData.append("finalsignoff_doc", data?.titleUrl || "");
      formData.append(
        "fso_status",
        data?.options == "Sent" ? "sent" : "signed"
      );

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}tax-user/${UserData?.id}/`,
        {
          method: "PATCH",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      toast.success("send successfully");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASEURL}tax-user/${UserData?.id}/`;
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setValue("options", result?.fso_status == "sent" ? "Sent" : "Signed");
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="container mt-5">
      <h2> Final Sign Off Document</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div className="mb-3">
          <label htmlFor="titleUrl" className="form-label">
            Title URL
          </label>
          <input
            type="url"
            className="form-control"
            id="titleUrl"
            placeholder="Enter URL"
            {...register("titleUrl", { required: "Title URL is required" })}
          />
          {errors.titleUrl && (
            <div className="text-danger">{errors.titleUrl.message}</div>
          )}
        </div> */}

        <div className="mb-3">
          <label className="form-label">Choose an option:</label>
          <br />
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="options"
              id="option1"
              value="Sent"
              {...register("options", { required: true })}
              defaultChecked
            />
            <label className="form-check-label" htmlFor="option1">
              Sent
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="options"
              id="option2"
              value="Signed"
              {...register("options", { required: true })}
            />
            <label className="form-check-label" htmlFor="option2">
              Signed
            </label>
          </div>
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
}

export default StatusFinalDocument;
