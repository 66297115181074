import React from "react";
import { Dropdown } from "react-bootstrap";
import Profile from "../../assets/images/profile_av.svg";
import { connect } from "react-redux";
import { Onopenmodalsetting } from "../../Redux/Actions/Action";
import { Link } from "react-router-dom";

function Header(props) {
  let user = JSON?.parse(localStorage?.getItem("userData")) || {};

  return (
    <div className="header" style={{ position: 'absolute', right: '0px', top: '10px', width: 'max-content'}}>
      <nav className="navbar pt-t">
        <div className="container-xxl">
          <div className="h-right d-flex align-items-center order-1">
            
            {/* <Dropdown className="dropdown user-profilem ms-2 ms-sm-3 d-flex align-items-center zindex-popover">
              <div className="u-info me-2">
                <p className="mb-0 text-end line-height-sm ">
                  <span className="font-weight-bold">
                    {user && user?.is_superuser === true
                      ? "Admin"
                      : user?.full_name == null
                      ? "John Quinn"
                      : user?.full_name}
                  </span>
                </p>
                <small>
                  {user && user?.is_superuser === true ? "Admin" : "Vendor"}{" "}
                  Profile
                </small>
              </div>
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 mb-3"
                href="#!"
                role="button"
              >
                <img
                  className="avatar lg rounded-circle img-thumbnail"
                  src={Profile}
                  alt="profile"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0 mt-5 ">
                <div className="card border-0   w280">
                  <div className="card-body pb-0 ">
                    <div className="d-flex py-1">
                      <img
                        className="avatar rounded-circle"
                        src={Profile}
                        alt=""
                      />
                      <div className="flex-fill ms-3">
                        <p className="mb-0">
                          <span className="font-weight-bold">
                            {" "}
                            {user && user?.is_superuser === true
                              ? "Admin"
                              : user?.full_name == null
                              ? "John Quinn"
                              : user?.full_name}
                          </span>
                        </p>
                        <small style={{wordBreak: 'break-all'}}> {user && user?.email}</small>
                      </div>
                    </div>
                    <div>
                      <hr className="dropdown-divider border-dark " />
                    </div>
                  </div>
                  <div className="list-group m-2 ">
                    {user.is_vendor && (
                      <>
                        <Link
                          to={process.env.PUBLIC_URL + "/vendor-detail"}
                          className="list-group-item list-group-item-action border-0 "
                        >
                          <i className="icofont-ui-user fs-5 me-3"></i>Profile
                          Page
                        </Link>

                        <a
                          href={process.env.PUBLIC_URL + "/sign-in/vendor"}
                          onClick={() => {
                            localStorage.removeItem("token");
                            localStorage.removeItem("userData");
                          }}
                          className="list-group-item list-group-item-action border-0 "
                        >
                          <i className="icofont-logout fs-5 me-3"></i>Signout
                        </a>
                      </>
                    )}

                    {!user.is_vendor && (
                      <a
                        href={process.env.PUBLIC_URL + "/sign-in/admin"}
                        onClick={() => {
                          localStorage.removeItem("token");
                          localStorage.removeItem("userData");
                        }}
                        className="list-group-item list-group-item-action border-0 "
                      >
                        <i className="icofont-logout fs-5 me-3"></i>Signout
                      </a>
                    )}
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown> */}
            
            <div className="setting ms-2">
              <a
                href="#!"
                onClick={(val) => {
                  props.Onopenmodalsetting(true);
                }}
              >
                <i className="icofont-gear-alt fs-5"></i>
              </a>
            </div>
          </div>
          <button
            className="navbar-toggler p-0 border-0 menu-toggle order-3"
            type="button"
            onClick={() => {
              var sidebar = document.getElementById("mainsidemenu");
              if (sidebar) {
                if (sidebar.classList.contains("open")) {
                  sidebar.classList.remove("open");
                } else {
                  sidebar.classList.add("open");
                }
              }
            }}
          >
            <span className="fa fa-bars"></span>
          </button>
        </div>
      </nav>
    </div>
  );
}

const mapSatetToProps = ({ Mainreducer }) => ({
  Mainreducer,
});

export default connect(mapSatetToProps, {
  Onopenmodalsetting,
})(Header);
