import React, { useEffect, useState } from "react";
import { FaEdit, FaSave, FaTrash } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import WorkSendModel from "./WorkSendModel";

const DirectStaffTable = ({
  heading,
  col1,
  col2,
  col3,
  col4,
  col5,
  col6,
  data,
  fetchAllData,
  isEditMode = false,
  sideBarKey,
  UserData,
  selectedYear,
}) => {
  const [editingRows, setEditingRows] = useState([]);
  const [loadingRows, setLoadingRows] = useState([]);
  const [tableData, setTableData] = useState([]);

  const year = selectedYear;

  useEffect(() => {
    const formattedData =
      data &&
      data?.map((item) => ({
        id: item?.id,
        name: item?.name,
        project: item?.projects,
        involvement: item?.involvement_responsibility,
        notes: item?.notes,
        rdPercentage: item?.percentage_of_time_spent_on_rd_project,
        salary: item?.total_yearly_salary,
        sheet_type: item?.sheet_type,
      }));
    setTableData(formattedData);
  }, [data]);

  const handleEditClick = (index) => {
    setEditingRows((prev) => {
      const updated = new Array(prev.length).fill(false);
      updated[index] = true;
      return updated;
    });
  };

  const handleSaveClick = async (index) => {
    setLoadingRows((prev) => {
      const updated = [...prev];
      updated[index] = true;
      return updated;
    });

    const row = tableData[index];
    const bodyData = {
      name: row?.name,
      projects: row?.project,
      involvement_responsibility: row?.involvement,
      notes: row?.notes,
      percentage_of_time_spent_on_rd_project: row?.rdPercentage,
      total_yearly_salary: row?.salary,
      sheet_type: sideBarKey,
      year: year,
      user: UserData?.id,
    };

    try {
      const url = row.id
        ? `${process.env.REACT_APP_BASEURL}workbooks/${row.id}/`
        : `${process.env.REACT_APP_BASEURL}workbooks/`;

      const method = row.id ? "PATCH" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken":
            "dGiYiWj9URCjX2wDiexhekM4K3PV3Yfknpj8MEJynsF96rJ7gDqAs4BG35Sxvw5b",
        },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setTimeout(() => {
        setLoadingRows((prev) => {
          const updated = [...prev];
          updated[index] = false;
          return updated;
        });
        setEditingRows((prev) => {
          const updated = [...prev];
          updated[index] = false;
          return updated;
        });
        fetchAllData();
      }, 1000); // Simulating a 1-second save operation
    } catch (error) {
      console.error("Failed to save data:", error);
      setLoadingRows((prev) => {
        const updated = [...prev];
        updated[index] = false;
        return updated;
      });
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index][field] = value;
    setTableData(updatedTableData);
  };

  const handleAddRow = () => {
    const newRow = {
      id: null,
      name: "",
      project: "",
      involvement: "",
      notes: "",
      rdPercentage: "",
      salary: "",
    };

    setTableData((prev) => {
      const updatedData = [...prev, newRow];
      setEditingRows(new Array(updatedData.length).fill(false)); // Reset all to false
      setEditingRows((prevEditingRows) => {
        const updatedEditingRows = [...prevEditingRows];
        updatedEditingRows[updatedData.length - 1] = true; // Set only the new row to be editable
        return updatedEditingRows;
      });
      return updatedData;
    });
  };

  const handleDeleteRow = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);

    const updatedEditingRows = [...editingRows];
    updatedEditingRows.splice(index, 1);
    setEditingRows(updatedEditingRows);

    const updatedLoadingRows = [...loadingRows];
    updatedLoadingRows.splice(index, 1);
    setLoadingRows(updatedLoadingRows);
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center bg-warning text-dark py-2">{heading}</h2>
      <div style={{ overflowX: "auto" }}>
        <table className="table table-bordered mt-4">
          <thead className="bg-warning text-center">
            <tr>
              <th>{col1}</th>
              <th>{col2}</th>
              <th>{col3}</th>
              <th>{col5}</th>
              <th>{col6}</th>
              {isEditMode && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData?.map((row, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={row.name}
                      onChange={(e) =>
                        handleInputChange(index, "name", e.target.value)
                      }
                      disabled={!editingRows[index]}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      value={row.project}
                      onChange={(e) =>
                        handleInputChange(index, "project", e.target.value)
                      }
                      disabled={!editingRows[index]}
                    >
                      <option>Select Project</option>
                      <option>Air Curtain Project</option>
                      <option>
                        Refurbishment Programmes for Rational Ovens
                      </option>
                      <option>Software and process Enhancements</option>
                      {/* Add more options as needed */}
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-select"
                      value={row.involvement}
                      onChange={(e) =>
                        handleInputChange(index, "involvement", e.target.value)
                      }
                      disabled={!editingRows[index]}
                    >
                      <option>Select Responsibility</option>
                      <option>
                        Scientific / Technological testing activities
                      </option>
                      <option>
                        Directly involved in resolving technological or
                        scientific challenges in project
                      </option>
                      <option>
                        Protoype analysis to resolve scientific or technological
                        uncertainty
                      </option>
                      <option>
                        Scientific or technological planning activities
                        associated with a project directly contribute to
                        resolving the scientific or technological uncertainty
                        associated with the project
                      </option>
                      <option>
                        Direct involvement in project deliverables to overcome
                        scientific or technological uncertainty
                      </option>
                      <option>
                        Direct involvement in high-level planning to resolve
                        scientific or technological uncertainty
                      </option>
                      <option>
                        Design and iteration phases across uncertainties faced
                        in project to overcome scientific or technological
                        uncertainty
                      </option>
                      <option>
                        Activities to adapt or create materials or equipment
                        needed to resolve the scientific or technological
                        uncertainty
                      </option>
                      <option>Activities to adapt or create software</option>
                      {/* Add more options as needed */}
                    </select>
                  </td>

                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter %"
                      value={row?.rdPercentage}
                      onChange={(e) =>
                        handleInputChange(index, "rdPercentage", e.target.value)
                      }
                      disabled={!editingRows[index]}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Spent"
                      value={row.salary}
                      onChange={(e) =>
                        handleInputChange(index, "salary", e.target.value)
                      }
                      disabled={!editingRows[index]}
                    />
                  </td>
                  {isEditMode && (
                    <td className="text-center">
                      {loadingRows[index] ? (
                        <Spinner animation="border" size="sm" />
                      ) : editingRows[index] ? (
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-success me-2"
                            onClick={() => handleSaveClick(index)}
                          >
                            <FaSave />
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDeleteRow(index)}
                          >
                            <FaTrash />
                          </button>
                        </div>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleEditClick(index)}
                        >
                          <FaEdit />
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center gap-2 mt-3">
        {isEditMode && (
          <button className="btn btn-secondary" onClick={handleAddRow}>
            Add More
          </button>
        )}
        <WorkSendModel UserData={UserData} />
      </div>
    </div>
  );
};

export default DirectStaffTable;
