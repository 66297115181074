import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { validatePassword } from "../../helper/validation";

function ResetPassword() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const location = useLocation();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("email", location?.state?.email);
      formData.append("password", data?.password);
      formData.append("confirm_password", data?.password2);
      // if (location.state?.userType === "vendor") {
      //   formData.append("email", location?.state?.email);
      //   formData.append("password", data?.password);
      //   formData.append("confirm_password", data?.password2);
      // } else {
      //   formData.append("email", location?.state?.email);
      //   formData.append("password", data?.password);
      //   formData.append("otp", location?.state?.otp);
      // }

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}password-reset/`,
        // /api/login_signup/${
        //   location?.state?.userType === "vendor"
        //     ? "vendor-password-reset"
        //     : "admin-otp-verify"
        // }/`
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData.msg);
        localStorage.removeItem("token");

        setTimeout(() => {
          navigate(
            `/sign-in/`
            // ${
            //   location?.state?.userType === "vendor" ? "vendor" : "admin"
            // }`
          );
        }, 2000);
      } else {
        const data = await response.json();
        toast.error(Object.values(data).join(", ") || "Something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };
  return (
    <div className="col-lg-6 d-flex justify-content-center">
      <div className="w-100 p-3 p-md-5 card border-0 shadow-sm">
        <form className="row g-1 p-3 p-md-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 text-center mb-5">
            <h1>Reset Password</h1>
          </div>

          <div className="col-12">
            <div className="mb-2">
              <label className="form-label">Password</label>
              <div className="position-relative passwordShow">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control form-control-lg"
                  placeholder="***************"
                  {...register("password", { validate: validatePassword })}
                />
                {showPassword ? (
                  <FaEye onClick={togglePasswordVisibility} />
                ) : (
                  <FaEyeSlash onClick={togglePasswordVisibility} />
                )}
              </div>
              {errors.password && (
                <span className="text-danger">{errors.password.message}</span>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <label className="form-label">Confirm password</label>
              <div className="position-relative passwordShow">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control form-control-lg"
                  placeholder="***************"
                  {...register("password2", {
                    required: "Confirm password is required",
                    validate: (value) =>
                      value === watch("password") || "Passwords do not match",
                  })}
                />
                {showConfirmPassword ? (
                  <FaEye onClick={toggleConfirmPasswordVisibility} />
                ) : (
                  <FaEyeSlash onClick={toggleConfirmPasswordVisibility} />
                )}
              </div>
              {errors.password2 && (
                <span className="text-danger">{errors.password2.message}</span>
              )}
            </div>
          </div>
          <div className="col-12 text-center mt-4">
            <Button
              type="submit"
              className="btn btn-lg btn-block btn-light lift text-uppercase w-100 p-3"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default ResetPassword;
