import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import { toast } from "react-toastify";

function DeleteModal({ id, setApiCallStatus }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteItem = async () => {
    setApiCallStatus(false);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}user/${id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        if (response.status === 404) {
          toast.error("User not found.");
        } else if (response.status === 401) {
          toast.error("Unauthorized. Please log in again.");
        } else {
          toast.error("Failed to delete the user.");
        }
      }

      toast.success("Deleted successfully");
      setApiCallStatus(true);
      handleClose(false);
    } catch (error) {
      setApiCallStatus(false);

      // Show error message to the user
      toast.error(
        error.message || "An error occurred while deleting the user."
      );

      // Log the error for debugging
      console.error("Delete User Error:", error);
    }
  };

  return (
    <>
      <img src={deleteIcon} alt="Delete" onClick={handleShow} />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleDeleteItem}
            style={{ backgroundColor: "red", borderColor: "red" }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteModal;
