import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BsFileEarmarkText } from "react-icons/bs";
import { FaDownload } from "react-icons/fa";

const DocumentItem = ({ title, fileUrl, description, UserData }) => {
  const handleDownload = () => {
    const fileLink = fileUrl;
    fetch(`${process.env.REACT_APP_BASEURL}presigned-url/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_link: fileLink,
      }),
    })
      .then((response) => {
        console.log("API Response:", response);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const downloadUrl = data?.presigned_url;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileLink;
        link.click();
      })
      .catch((error) => console.error("Download error:", error));
  };

  return (
    <div className="d-flex align-items-center mb-3 document-item">
      <BsFileEarmarkText size={40} className="me-3 text-primary" />
      <div className="flex-grow-1">
        <p className="mb-0">{title}</p>
        <small className="text-muted">{description}</small>
      </div>
      <FaDownload
        size={30}
        style={{ color: "black" }}
        onClick={handleDownload}
      />
    </div>
  );
};

const DocumentUpload = ({ UserData }) => {
  const documents = [
    {
      title: "Accounts",
      fileUrl: UserData?.busi_accounts,
      description: "Accounts",
    },
    {
      title: "VatQ1",
      fileUrl: UserData?.Vatq1,
      description: "VatQ1",
    },
    {
      title: "VatQ2",
      fileUrl: UserData?.Vatq2,
      description: "VatQ2",
    },
    {
      title: "VatQ3",
      fileUrl: UserData?.Vatq3,
      description: "VatQ3",
    },
    {
      title: "VatQ4",
      fileUrl: UserData?.Vatq4,
      description: "VatQ4",
    },
    {
      title: "Business Bank Statement",
      fileUrl: UserData?.businessbankstatement,
      description: "Business Bank Statement",
    },
  ];

  return (
    <div className="container mt-4 document-upload">
      <h3 className="text-primary mb-3">Upload Documents</h3>
      {documents &&
        documents?.map((doc, index) => (
          <DocumentItem
            key={index}
            title={doc?.title}
            fileUrl={doc?.fileUrl}
            description={doc.description}
            UserData={UserData}
          />
        ))}
    </div>
  );
};

export default DocumentUpload;
