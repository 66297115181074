import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Images from "../../assets/images/verify.svg";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

function OTP() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      const otp = data?.one + data?.two + data?.three + data?.four;
      formData.append("otp", otp);
      formData.append("email", location?.state?.email);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}api/login_signup/vendor-otp-verify/`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData?.msg || "OTP verification successful!");
        localStorage.removeItem("token");

        setTimeout(() => {
          navigate("/sign-in");
        }, 2000);
      } else {
        const data = await response.json();
        toast.error(Object.values(data).join(", ") || "something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("something went wrong");
      console.error(error, "Error submitting form:");
    }
  };
  return (
    <div
      className="w-100 p-3 p-md-5 card border-0 shadow-sm"
      style={{ maxWidth: "32rem" }}
    >
      <form className="row g-1 p-3 p-md-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12 text-center mb-5">
          <img src={Images} className="w240 mb-4" alt="" />
          <h1>OTP Verification</h1>
          <span>
            We sent a OTP verification code to your email. Enter the code from
            the email in the field below.
          </span>
        </div>
        <div className="col">
          <div className="mb-2">
            <input
              type="number"
              className="form-control form-control-lg text-center"
              placeholder="-"
              {...register("one", { required: true })}
            />
            {errors.one && (
              <span className="text-danger">This field is required</span>
            )}
          </div>
        </div>
        <div className="col">
          <div className="mb-2">
            <input
              type="number"
              className="form-control form-control-lg text-center"
              placeholder="-"
              {...register("two", { required: true })}
            />
            {errors.two && (
              <span className="text-danger">This field is required</span>
            )}
          </div>
        </div>
        <div className="col">
          <div className="mb-2">
            <input
              type="number"
              className="form-control form-control-lg text-center"
              placeholder="-"
              {...register("three", { required: true })}
            />
            {errors.three && (
              <span className="text-danger">This field is required</span>
            )}
          </div>
        </div>
        <div className="col">
          <div className="mb-2">
            <input
              type="number"
              className="form-control form-control-lg text-center"
              placeholder="-"
              {...register("four", { required: true })}
            />
            {errors.four && (
              <span className="text-danger">This field is required</span>
            )}
          </div>
        </div>
        <div className="col-12 text-center mt-4">
          <Button
            type="submit"
            className="btn btn-lg btn-block btn-light lift text-uppercase w-100 p-3"
          >
            Verify my account
          </Button>
        </div>
        <div className="col-12 text-center mt-4">
          <span>
            Haven't received it?{" "}
            <Link to="#!" className="text-secondary">
              Resend a new code.
            </Link>
          </span>
        </div>
      </form>
    </div>
  );
}
export default OTP;
