import React, { useEffect, useState } from "react";
import "../../TaxReliefUsers/components/ApplicationStatus.css";

const statusMapping = {
  "Discovery Call": "discovery_call",
  "AML Check Complete": "aml_check_complete",
  "Business Finance Review": "business_finance_review",
  "Documents Provided": "documents_provided",
  "Application Submitted": "application_submitted",
  "Options Presented": "options_presented",
  "Business Finance Confirmed": "business_finance_confirmed",
  "T's & C's Signed": "ts_and_cs_signed",
  "Finance Delivered": "finance_delivered",
};
const statuses = Object.keys(statusMapping);

const BusinessesStatus = ({ UserData }) => {
  const [activeStatus, setActiveStatus] = useState(null);

  const fetchData = async () => {
    try {
      let response = null;

      if (UserData?.id) {
        response = await fetch(
          `${process.env.REACT_APP_BASEURL}${UserData.id}/`
        );
      }

      if (response && response.ok) {
        const data = await response.json();
        const backendStatus = data?.business_status;
        const displayStatus = Object.keys(statusMapping).find(
          (key) => statusMapping[key] === backendStatus
        );
        setActiveStatus(displayStatus);
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [UserData?.id]);

  const handleStatusClick = async (status) => {
    setActiveStatus(status);

    const backendValue = statusMapping[status];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}user/${UserData.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            business_status: backendValue,
          }),
        }
      );

      if (response.ok) {
        console.log("Status updated successfully");
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <div className="application-status-container">
      <div className="header">
        <h1>Application Status</h1>
      </div>
      <div className="status-list">
        {statuses.map((status) => (
          <div
            key={status}
            className={`status-item ${activeStatus === status ? "active" : ""}`}
            onClick={() => handleStatusClick(status)}
          >
            <div className="status-circle"></div>
            <span>{status}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessesStatus;
