import React, { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import PageHeader1 from "../../../components/common/PageHeader1";
import EditLoe from "../../../components/Modal/EditLoe";
import { toast } from "react-toastify";
import SendLoeModel from "./SendLOE";

const PdfViewer = ({ UserData }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState("2024-2025");
  const [loeData, setLoeData] = useState(null);
  const currentYear = new Date().getFullYear();
  const currentYearEnd = currentYear + 1;
  const yearOptions = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => {
    const yearStart = 2000 + i;
    const yearEnd = yearStart + 1;
    return `${yearStart}-${yearEnd}`;
  });

  const handleYearChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedYear(selectedValue);
  };

  const hasBusinessRole = loeData?.results?.some(
    (item) => item?.company_info?.role === "business"
  );
  const fetchLoeData = async () => {
    try {
      if (UserData?.id) {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}document/?user_id=${UserData?.id}&year=2024-2025`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        const hasBusinessRole = result?.results?.some(
          (item) => item?.company_info?.role === "business"
        );
        if (hasBusinessRole) {
          setLoeData(result);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchLoeData();
  }, [UserData?.id]);

  const businessItem = loeData?.results?.find(
    (item) => item?.company_info?.role === "business"
  );

  const pdfUrl =
    businessItem?.loeafter_sign || businessItem?.loebefore_signed || "";

  return (
    <div style={{ height: 600 }}>
      {showModal && (
        <EditLoe
          setShowModal={setShowModal}
          showModal={showModal}
          userType="business"
          UserData={UserData}
          selectedYear={selectedYear}
          loeData={loeData}
          businessItem={businessItem}
          fetchLoeData={fetchLoeData}
        />
      )}
      <PageHeader1
        pagetitle="LOE"
        modalbutton={() => {
          return (
            <div className="col-auto d-flex w-sm-300" style={{ gap: "10px" }}>
              <input className="form-select" type="text" value={"2024-2025"} />
              {UserData && Object.keys(UserData).length > 0 && (
                <SendLoeModel loeData={businessItem?.id} UserData={UserData} />
              )}

              <button
                type="button"
                onClick={() => {
                  setShowModal(true);
                }}
                className="btn btn-primary btn-set-task w-sm-100"
              >
                <i className="icofont-plus-circle me-2 fs-6"></i>
                {businessItem?.company_info ? "Update LOE" : "Generate LOE"}
              </button>
            </div>
          );
        }}
      />
      {loeData?.results.length > 0 && hasBusinessRole ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer fileUrl={pdfUrl} />
        </Worker>
      ) : (
        <h4 style={{ color: "red" }}>No LOE found</h4>
      )}
    </div>
  );
};

export default PdfViewer;
