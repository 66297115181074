export async function fetchData(endpoint, method = "GET", body = null) {
  try {
    console.log(
      process.env.REACT_APP_BASEURL,
      endpoint,
      "endpointendpointendpointendpointendpoint"
    );
    const response = await fetch(
      `${process.env.REACT_APP_BASEURL}${endpoint}`,
      {
        method,
        // headers: {
        //     Authorization: `${localStorage.getItem("token")}`,
        //     'Content-Type': 'application/json',
        // },
        body: body ? JSON.stringify(body) : null,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data. Please try again.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
