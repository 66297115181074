import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

const SendLoeModel = ({ UserData, loeData, con }) => {
  const [formDatas, setFormData] = useState({
    year: "2024-2025",
    email: UserData?.email,
  });

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleApiCall = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("user", UserData?.id);
    formData.append("document", loeData);
    formData.append("email", formDatas?.email || UserData?.email);
    formData.append("loe_type", "before");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}loesending/`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("API response:", result);
        handleClose();
        toast.success("LOE send your email");
      } else {
        toast.error("loe not found please select loe");
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Send LOE
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send Loe Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleApiCall}>
            <Form.Group controlId="formYear">
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={formDatas?.email}
                  onChange={(e) =>
                    setFormData({ ...formDatas, email: e.target.value })
                  }
                />
              </Form.Group>
            </Form.Group>
            <div className="d-flex align-items-center gap-2 mt-3">
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Send Data
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SendLoeModel;
