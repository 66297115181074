import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeader1 from "../../components/common/PageHeader1";
import { Tab, Nav } from "react-bootstrap";
import BackIcon from "../../assets/images/back-btn.svg";
import BasicInfo from "./components/basicInfo";
import DocumentUpload from "./components/uploadDocs";
import WorkBook from "./components/workbook";
import Meetings from "./components/meetings";
import Questionnaire from "./components/questionnaire";
import PdfViewer from "./components/LOEviewer";
import { fetchData } from "../../helper/apiFunction";
import {
  MdPerson,
  MdDescription,
  MdMeetingRoom,
  MdOutlineStackedBarChart,
} from "react-icons/md";
import { FaFileSignature, FaClipboardList, FaBook } from "react-icons/fa";
import BusinessesStatus from "./components/BusinessesStatus";
import StatusFinalDocument from "./components/StatusFinalDocument";
import { LuClipboardSignature } from "react-icons/lu";

const EditUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const UserData = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("tabNew1");
  const [activeSubKey, setActiveSubKey] = useState("");
  const [selectedYear, setSelectedYear] = useState("2023-2024");
  const [taxReliefData, setTaxReliefData] = useState({});
  const [directStaffData, setDirectStaffData] = useState([]);
  const [subcontractsData, setSubcontractsData] = useState([]);
  const [consumablesData, setConsumablesData] = useState([]);
  const [itSoftwareData, setItSoftwareData] = useState([]);
  const [activeQusSubKey, setActiveQusSubKey] = useState("subSection1");
  const [discoveryData, setDiscoveryData] = useState(null);
  const [sideBarKey, setSideBar] = useState("directstaff");

  const currentYear = new Date().getFullYear();
  const currentYearEnd = currentYear + 1;

  // Generate year options from 2000-01 up to the current year
  const yearOptions = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => {
    const yearStart = 2000 + i;
    const yearEnd = yearStart + 1;
    return `${yearStart}-${yearEnd}`;
  });

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const fetchAllData = async () => {
    try {
      const [taxRelief, directStaff, subcontracts, consumables, itSoftware] =
        await Promise.all([
          fetchData(`business-user/${UserData?.id}`),
          fetchData(
            `workbooks/?year=2024-2025&sheet_type=directstaff&user_id=${UserData?.id}`,
            "GET"
          ),
          fetchData(
            `workbooks/?year=2024-2025&sheet_type=subcontracts&user_id=${UserData?.id}`,
            "GET"
          ),
          fetchData(
            `workbooks/?year=2024-2025&sheet_type=consumables&user_id=${UserData?.id}`,
            "GET"
          ),
          fetchData(
            `workbooks/?year=2024-2025&sheet_type=itsoftwarecosts&user_id=${UserData?.id}`,
            "GET"
          ),
        ]);

      setTaxReliefData(taxRelief);
      setDirectStaffData(directStaff);
      setSubcontractsData(subcontracts);
      setConsumablesData(consumables);
      setItSoftwareData(itSoftware);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, [UserData?.id, selectedYear]);

  useEffect(() => {
    const fetchTechnicalData = async () => {
      try {
        const [discoveryData] = await Promise.all([
          fetchData(
            `answers/?user_id=${UserData?.id}&category=Discovery Call Questionnaire`,
            "GET"
          ),
        ]);
        setDiscoveryData(discoveryData?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTechnicalData();
  }, []);

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <div className="d-flex align-items-center">
          <img
            src={BackIcon}
            alt="info"
            className="backbtn-icon me-3"
            style={{ width: "30px", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <PageHeader1
            pagetitle="Edit"
            modalbutton={() => {
              return <div className="col-auto d-flex w-sm-100"></div>;
            }}
          />
        </div>
        <div className="row clearfix g-3">
          <div className="col-sm-12 mt-3">
            <div className="card">
              <div className="card-body">
                <div
                  id="myProjectTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      {isLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "70vh" }}
                        >
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="tabbing-box d-flex">
                          <Tab.Container
                            id="left-tabs-example"
                            activeKey={activeKey}
                            onSelect={setActiveKey}
                          >
                            <Nav
                              variant="pills"
                              className="flex-column nav-pills-custom"
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="tabNew1"
                                  className="nav-link-custom d-flex align-items-center"
                                >
                                  <MdPerson className="me-2 fs-3" />
                                  Personal Info
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="tabNew2"
                                  className="nav-link-custom d-flex align-items-center"
                                >
                                  <MdDescription className="me-2 fs-2" />
                                  Documents Uploaded
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="tabNew3"
                                  className="nav-link-custom d-flex align-items-center"
                                >
                                  <MdMeetingRoom className="me-2 fs-3" />
                                  Meetings
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="tabNew4"
                                  className="nav-link-custom d-flex align-items-center"
                                >
                                  <FaFileSignature className="me-2 fs-4" />
                                  LOE
                                </Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item>
                                <Nav.Link
                                  eventKey="tabNew48"
                                  className="nav-link-custom d-flex align-items-center"
                                >
                                  <LuClipboardSignature className="me-2 fs-4" />
                                  Final Sign of Document
                                </Nav.Link>
                              </Nav.Item> */}
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="tabNew7"
                                  className="nav-link-custom d-flex align-items-center"
                                >
                                  <MdOutlineStackedBarChart className="me-2 fs-4" />
                                  Businesses Status
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="tabNew5"
                                  className="nav-link-custom d-flex align-items-center"
                                >
                                  <FaClipboardList className="me-2 fs-4" />
                                  Questionnaire
                                </Nav.Link>
                                {activeKey === "tabNew5" && (
                                  <div className="subsections">
                                    <Nav.Item>
                                      <Nav.Link
                                        className={`sub-nav-link-custom ${
                                          activeQusSubKey === "subSection1"
                                            ? "active-sub"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          setActiveQusSubKey("subSection1")
                                        }
                                      >
                                        WelCome Question
                                      </Nav.Link>
                                    </Nav.Item>
                                  </div>
                                )}
                              </Nav.Item>
                              {/* <Nav.Item>
                                <Nav.Link
                                  eventKey="tabNew6"
                                  className="nav-link-custom d-flex align-items-center"
                                >
                                  <FaBook className="me-2 fs-4" />
                                  Workbook
                                </Nav.Link>
                               
                                <input type="text" value={"2024-2025"} />
                              </Nav.Item> */}
                              {/* Subsections for Workbook */}
                              {activeKey === "tabNew6" && selectedYear && (
                                <div className="subsections">
                                  <Nav.Item>
                                    <Nav.Link
                                      className={`sub-nav-link-custom ${
                                        activeSubKey === "subSection1"
                                          ? "active-sub"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setActiveSubKey("subSection1");
                                        setSideBar("directstaff");
                                      }}
                                    >
                                      Direct Staff
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      className={`sub-nav-link-custom ${
                                        activeSubKey === "subSection2"
                                          ? "active-sub"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setActiveSubKey("subSection2");
                                        setSideBar("subcontracts");
                                      }}
                                    >
                                      Sub-contractors
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      className={`sub-nav-link-custom ${
                                        activeSubKey === "subSection3"
                                          ? "active-sub"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setActiveSubKey("subSection3");
                                        setSideBar("consumables");
                                      }}
                                    >
                                      Consumables
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      className={`sub-nav-link-custom ${
                                        activeSubKey === "subSection4"
                                          ? "active-sub"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setActiveSubKey("subSection4");
                                        setSideBar("itsoftwarecosts");
                                      }}
                                    >
                                      IT & Software Costs
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      className={`sub-nav-link-custom ${
                                        activeSubKey === "subSection5"
                                          ? "active-sub"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setActiveSubKey("subSection5");
                                        setSideBar("Other");
                                      }}
                                    >
                                      Other
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                              )}
                            </Nav>
                            <Tab.Content className="tab-content-custom">
                              <Tab.Pane eventKey="tabNew1">
                                {taxReliefData &&
                                  Object.keys(taxReliefData)?.length > 0 && (
                                    <BasicInfo
                                      mode={"edit"}
                                      data={taxReliefData}
                                    />
                                  )}
                              </Tab.Pane>
                              <Tab.Pane eventKey="tabNew2">
                                {taxReliefData &&
                                  Object.keys(taxReliefData)?.length > 0 && (
                                    <DocumentUpload UserData={taxReliefData} />
                                  )}
                              </Tab.Pane>
                              <Tab.Pane eventKey="tabNew3">
                                {taxReliefData &&
                                  Object.keys(taxReliefData)?.length > 0 && (
                                    <Meetings UserData={taxReliefData} />
                                  )}
                              </Tab.Pane>
                              <Tab.Pane eventKey="tabNew48">
                                {taxReliefData &&
                                  Object.keys(taxReliefData)?.length > 0 && (
                                    <StatusFinalDocument
                                      UserData={taxReliefData}
                                    />
                                  )}
                              </Tab.Pane>
                              <Tab.Pane eventKey="tabNew4">
                                {taxReliefData ? (
                                  <PdfViewer UserData={taxReliefData} />
                                ) : null}
                              </Tab.Pane>
                              <Tab.Pane eventKey="tabNew7">
                                {taxReliefData &&
                                  Object.keys(taxReliefData)?.length > 0 && (
                                    <BusinessesStatus
                                      UserData={taxReliefData}
                                    />
                                  )}
                              </Tab.Pane>

                              <Tab.Pane eventKey="tabNew5">
                                {activeQusSubKey === "subSection1" && (
                                  <Questionnaire qus={taxReliefData} />
                                )}
                              </Tab.Pane>

                              <Tab.Pane eventKey="tabNew6">
                                {/* <div>Main Workbook Content</div> */}
                                {activeSubKey === "subSection1" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`Direct Staff ${selectedYear}`}
                                      col1="Name(Staff)"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      isEditMode={true}
                                      data={directStaffData}
                                      selectedYear={selectedYear}
                                      sideBarKey={sideBarKey}
                                      UserData={taxReliefData}
                                      fetchAllData={fetchAllData}
                                    />
                                  </div>
                                )}
                                {activeSubKey === "subSection2" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`FINANCIAL YEAR ${selectedYear}`}
                                      col1="Name(subcontractor)"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      isEditMode={true}
                                      data={subcontractsData}
                                      selectedYear={selectedYear}
                                      sideBarKey={sideBarKey}
                                      UserData={taxReliefData}
                                      fetchAllData={fetchAllData}
                                    />
                                  </div>
                                )}
                                {activeSubKey === "subSection3" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`FINANCIAL YEAR ${selectedYear}`}
                                      col1="Name/type of consumable"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      isEditMode={true}
                                      data={consumablesData}
                                      selectedYear={selectedYear}
                                      sideBarKey={sideBarKey}
                                      UserData={taxReliefData}
                                      fetchAllData={fetchAllData}
                                    />
                                  </div>
                                )}
                                {activeSubKey === "subSection4" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`FINANCIAL YEAR ${selectedYear}`}
                                      col1="Name/type of software"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      isEditMode={true}
                                      data={itSoftwareData}
                                      selectedYear={selectedYear}
                                      sideBarKey={sideBarKey}
                                      UserData={taxReliefData}
                                      fetchAllData={fetchAllData}
                                    />
                                  </div>
                                )}
                                {activeSubKey === "subSection5" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`FINANCIAL YEAR ${selectedYear}`}
                                      col1="Name"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      isEditMode={true}
                                      data={itSoftwareData}
                                      selectedYear={selectedYear}
                                      sideBarKey={sideBarKey}
                                      UserData={taxReliefData}
                                      fetchAllData={fetchAllData}
                                    />
                                  </div>
                                )}
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
