import React from 'react';
import ImageSrc from "../../assets/images/login-img.svg";
import mainLogo from "../../assets/images/logo.jpg";

function AuthLeft() {
    return (
        <div className="col-lg-6 d-none d-lg-flex justify-content-center mt-5">
            <div>
                <div className="text-center mb-5">
                    {/* <i className="bi bi-bag-check-fill  text-primary" style={{ fontSize: 90 }}></i> */}
                    <img src={mainLogo} alt="login-img" style={{width:'180px', height:'auto'}} />
                </div>
                <div className="mb-5">
                    <h2 className="color-900 text-center">A few clicks is all it takes.</h2>
                </div>
                <div className="">
                    <img src={ImageSrc} alt="login-img" />
                </div>
            </div>
        </div>
    );
}
export default AuthLeft;