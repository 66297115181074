import { Modal, Button } from "react-bootstrap";

export default function ConfimationModal({
  show,
  handleClose,
  onConfirm,
  message = "",
}) {
  return (
    <Modal show={show !== ""} onHide={handleClose} centered backdrop="static">
      <Modal.Title className="bg-dark pt-2 pb-1">
        <h5 className="text-center text-light">
          Confirmation
        </h5>
      </Modal.Title>
      <Modal.Body>
        <p className="text-center mb-4 font-weight-semi-bold">
          {message ? message : "Are you sure you want to remove this item?"}
        </p>
        <div className="d-flex justify-content-center w-100">
          <Button
            variant="danger"
            onClick={() => onConfirm(show)}
            className="me-2 w-75"
          >
            Yes
          </Button>
          <Button variant="secondary" className="me-2 w-75" onClick={handleClose}>
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
