import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import menu from "../Data/Menu/newMenu.json";
import vendormenu from "../Data/Menu/vendorMenu.json";
import menu2 from "../Data/Menu/menu2.json";
import { Dropdown } from "react-bootstrap";
import Profile from "../../assets/images/profile_av.svg";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/logo.jpg";

function Sidebar(props) {
  const navigate = useNavigate();
  const logoutLinkRef = useRef(null);

  const [isSidebarMini, setIsSidebarMini] = useState(false);
  const [menuData, setMenuData] = useState([...menu.menu]);

  const [isLogoutModal, setIsLogoutModal] = useState(false);

  const openChildren = (id) => {
    var otherTabs = document.getElementsByClassName("has-children");
    if (otherTabs) {
      for (var i = 0; i < otherTabs.length; i++) {
        if (otherTabs[i].id !== id) {
          otherTabs[i].className = otherTabs[i].className.replace(" show", "");
          if (otherTabs[i].parentElement.children.length > 1) {
            otherTabs[i].parentElement.children[0].setAttribute(
              "aria-expanded",
              "false"
            );
          }
        }
      }
    }
    var menutab = document.getElementById(id);
    if (menutab) {
      if (menutab.classList.contains("show")) {
        menutab.classList.remove("show");
        if (menutab.parentElement.children.length > 1) {
          menutab.parentElement.children[0].setAttribute(
            "aria-expanded",
            "false"
          );
        }
      } else {
        menutab.classList.add("show");
        if (menutab.parentElement.children.length > 1) {
          menutab.parentElement.children[0].setAttribute(
            "aria-expanded",
            "true"
          );
        }
      }
    }
  };
  const openChildren1 = (id) => {
    var otherTabs = document.getElementsByClassName("has-children");
    if (otherTabs) {
      for (var i = 0; i < otherTabs.length; i++) {
        otherTabs[i].className = otherTabs[i].className.replace(" show", "");
      }
    }
    var menutab = document.getElementById(id);
    if (menutab) {
      menutab.classList.add("show");
      if (menutab.parentElement.children.length > 1) {
        menutab.parentElement.children[0].setAttribute("aria-expanded", "true");
      }
    }
  };

  const GotoChangeMenu = (val) => {
    if (val === "UI Components") {
      navigate("ui-alerts");
      setMenuData([...menu2.menu2]);
    } else {
      navigate("dashboard");
      setMenuData([...menu.menu]);
    }
  };
  const { activekey } = props;

  let user = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (user && user.is_vendor) {
      setMenuData([...vendormenu.menu]);
    }
  }, []);

  return (
    <>
      <div
        id="mainsidemenu"
        className={`sidebar px-3 py-3 py-md-3 me-0 ${
          isSidebarMini ? "sidebar-mini" : ""
        }`}
        style={{ overflow: "visible" }}
      >
        <div className="d-flex flex-column h-100">
          <Dropdown className="dropdown user-profilem d-flex align-items-center zindex-popover gap-1">
            <Dropdown.Toggle
              as="a"
              className="nav-link dropdown-toggle pulse p-0 d-flex align-items-center"
              href="#!"
              role="button"
            >
              <img
                className="avatar lg rounded-circle img-thumbnail"
                src={
                  user && user?.is_superuser === true
                    ? Profile
                    : (user && user?.image) ?? Profile
                }
                alt="profile"
              />
              <div className="flex-fill ms-2">
                <p className="mb-0">
                  <span className="font-weight-bold text-white">
                    {" "}
                    {user && user?.is_superuser === true
                      ? "Admin"
                      : user?.full_name == null
                      ? "John Quinn"
                      : user?.full_name}
                  </span>
                </p>
                <small
                  className="text-white"
                  style={{
                    wordBreak: "break-all",
                    display: "block",
                    whiteSpace: "normal",
                  }}
                >
                  {" "}
                  {user && user?.email}
                </small>
              </div>
            </Dropdown.Toggle>
          </Dropdown>

          <ul className="menu-list flex-grow-1 mt-3">
            {menuData.map((d, i) => {
              if (d.isToggled) {
                return (
                  <li key={"shsdg" + i}>
                    <a
                      className={`m-link `}
                      href="#!"
                      onClick={(e) => {
                        e.preventDefault();
                        GotoChangeMenu(d.name);
                      }}
                    >
                      <i className={d.iconClass}></i>
                      <span>{d.name}</span>
                    </a>
                  </li>
                );
              }
              if (d?.children?.length === 0) {
                return (
                  <li key={"dsfshsdg" + i} className=" collapsed">
                    <Link
                      to={process.env.PUBLIC_URL + "/" + d.routerLink[0]}
                      className={`m-link ${
                        d.routerLink[0] === activekey ? "active" : ""
                      }`}
                    >
                      <i className={d.iconClass}></i>
                      <span>{d.name}</span>
                      <span className="ms-auto text-end fs-5"></span>
                    </Link>
                  </li>
                );
              }

              return (
                <li key={"shsdg" + i} className=" collapsed">
                  <a
                    className={`m-link ${
                      d.children.filter(
                        (d) => "/" + d.routerLink[0] === activekey
                      ).length > 0
                        ? "active"
                        : ""
                    }`}
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      openChildren("menu-Pages" + i);
                    }}
                  >
                    <i className={d.iconClass}></i>
                    <span>{d.name}</span>
                    <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                  </a>
                  {d?.children?.length > 0 ? (
                    <ul
                      className="sub-menu collapse has-children"
                      id={"menu-Pages" + i}
                    >
                      {d?.children?.map((data, ind) => {
                        if (d.children.length > 0) {
                          if (activekey === "/" + data.routerLink[0]) {
                            setTimeout(() => {
                              openChildren1("menu-Pages" + i);
                            }, 500);
                          }
                        }

                        return (
                          <li key={"jfdgj" + ind}>
                            <Link
                              className={
                                activekey === "/" + data.routerLink[0]
                                  ? "ms-link active"
                                  : "ms-link"
                              }
                              to={
                                process.env.PUBLIC_URL +
                                "/" +
                                data.routerLink[0]
                              }
                            >
                              {" "}
                              <span>{data.name}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </li>
              );
            })}
          </ul>

          <a href="/" className="mb-0 brand-icon m-auto">
            <span className="logo-icon">
              <img
                src={logo}
                alt="levy gera"
                style={{ width: "100px", height: "auto", borderRadius: "4px" }}
              />
            </span>
          </a>

          <div
            className="list-group m-2 "
            onClick={() => {
              setIsLogoutModal(true);
            }}
          >
            <span className="list-group-item list-group-item-action border-0 cursor-pointer">
              <i className="icofont-logout fs-5 me-3"></i>Signout
            </span>
            {user?.is_vendor ? (
              <a
                ref={logoutLinkRef}
                href="/sign-in/vendor"
                style={{ display: "none" }}
              >
                Go to login
              </a>
            ) : (
              <a
                ref={logoutLinkRef}
                href="/sign-in/admin"
                style={{ display: "none" }}
              >
                Go to login
              </a>
            )}
          </div>

          <button
            type="button"
            className="btn btn-link sidebar-mini-btn text-light"
            onClick={() => {
              setIsSidebarMini(!isSidebarMini);
            }}
          >
            <span className="ms-2">
              <i className="icofont-bubble-right"></i>
            </span>
          </button>
        </div>
      </div>

      <Modal
        show={isLogoutModal}
        onHide={() => {
          setIsLogoutModal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header
          className="modal-header"
          style={{
            position: "absolute",
            top: "14px",
            right: "14px",
            zIndex: "99",
            border: "none",
            width: "max-content",
          }}
        ></Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to logout?
              </h4>
              <div className="LogouModal">
                <button
                  onClick={() => {
                    setIsLogoutModal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>

                <button
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("userData");
                    // localStorage.removeItem("role");
                    setTimeout(() => {
                      if (user?.is_vendor) {
                        // navigate("/sign-in/vendor", { replace: true });
                        navigate("/sign-in", { replace: true });
                        // logoutLinkRef.current.click();
                      } else {
                        // navigate("/sign-in/admin", { replace: true });
                        navigate("/sign-in", { replace: true });

                        // logoutLinkRef.current.click();
                      }
                    }, 1000);
                  }}
                  type="button"
                  className="btn btn-primary"
                >
                  Logout
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Sidebar;
