import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const EventItem = ({ start_time, end_time, status, location }) => (
  <div className="d-flex align-items-center mb-3 p-3 border rounded">
    <div className="flex-grow-1">
      <div>
        <strong>Start Time:</strong> {new Date(start_time).toLocaleString()}
      </div>
      <div>
        <strong>End Time:</strong> {new Date(end_time).toLocaleString()}
      </div>
      <div>
        <strong>Meeting Type:</strong>
        &nbsp;{" "}
        {location?.type === "google_conference" ? "Google Meet" : "Zoom Meet"}
      </div>
      <div>
        <strong>Meeting Link:</strong>{" "}
        <Link to={location?.join_url} target="blank">
          {location?.join_url}
        </Link>
      </div>
    </div>
    <div className="ml-3">
      <span className="badge badge-info">{status}</span>
    </div>
  </div>
);
const EventList = ({ events }) => (
  <div>
    {events &&
      events?.map((event, index) => {
        return <EventItem key={index} {...event} />;
      })}
  </div>
);

const Meetings = ({ UserData }) => {
  const [upcomingEvents, setUpcoming] = useState([]);
  const [completedEvents, setPending] = useState([]);

  const callCalendlyEventsAPI = async () => {
    try {
      const email = UserData?.email;
      console.log(email, "email sent successfully");
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}calendly/events/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error(
          "HTTP Error:",
          response.status,
          response.statusText,
          errorText
        );
        throw new Error(
          `Network response was not ok: ${response.status} ${response.statusText}`
        );
      }

      const result = await response.json();
      setPending(result?.pending);
      setUpcoming(result?.upcoming);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };
  useEffect(() => {
    callCalendlyEventsAPI();
  }, []);

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <h2>Meetings Overview</h2>
          <p>
            Your tasks, appointments, and any other sales activities you've
            lined up for today, upcoming & past.
          </p>
        </div>
      </div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="upcoming">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="upcoming">Upcoming</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="pending">Pending</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="mt-3">
          <Tab.Pane eventKey="upcoming">
            <EventList events={upcomingEvents} />
          </Tab.Pane>
          <Tab.Pane eventKey="completed">
            <EventList events={completedEvents} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Meetings;
