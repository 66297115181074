import React, { useEffect, useState } from "react";
import "./ApplicationStatus.css";

const statusMapping = {
  "Estimated Tax Benefit For Year 2025: $8000": "estimated_tax_benefit",
  "AML Check Complete": "aml_check_complete",
  "Preparing Your Tax Relief Claim": "preparing_tax_claim",
  "Financial And Technical Elements Completed":
    "financial_and_technical_completed",
  "Final Sign Off Ready For Client Review": "final_sign_off_ready",
  "Final Sign Off Sent": "final_sign_off_sent",
  "Claim Filed": "claim_field",
  "Submission Receipt": "submission_receipt",
  "Claim Being Processed By HMRC": "claim_being_processed",
  "Claim Processed By HMRC": "claim_processed",
};

const statuses = Object.keys(statusMapping);

const ApplicationStatus = ({ UserData }) => {
  const [activeStatus, setActiveStatus] = useState(null);

  const fetchData = async () => {
    try {
      let response = null;

      if (UserData?.id) {
        response = await fetch(
          `${process.env.REACT_APP_BASEURL}user/${UserData.id}/`
        );
      }

      if (response && response.ok) {
        const data = await response.json();
        const backendStatus = data?.tax_status;
        const displayStatus = Object.keys(statusMapping).find(
          (key) => statusMapping[key] === backendStatus
        );
        setActiveStatus(displayStatus);
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [UserData?.id]);

  const handleStatusClick = async (status) => {
    setActiveStatus(status);

    const backendValue = statusMapping[status];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}user/${UserData.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tax_status: backendValue,
          }),
        }
      );

      if (response.ok) {
        console.log("Status updated successfully");
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <div className="application-status-container">
      <div className="header">
        <h1>Application Status</h1>
      </div>
      <div className="status-list">
        {statuses.map((status) => (
          <div
            key={status}
            className={`status-item ${activeStatus === status ? "active" : ""}`}
            onClick={() => handleStatusClick(status)}
          >
            <div className="status-circle"></div>
            <span>{status}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplicationStatus;
