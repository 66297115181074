import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import PageHeader1 from "../../components/common/PageHeader1";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FilterComponent } from "../../components/common/FilterComponent";
import StatusButton from "./actions";

function TaxReliefUsers() {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [apiCallStatus, setApiCallStatus] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  async function getTaxUsers() {
    try {
      console.log(
        "process.env.REACT_APP_BASEURL",
        process.env.REACT_APP_BASEURL
      );
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}tax-user/`,
        {
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const results = await response.json();
      console.log(results, "success  ");
      setTableData(results);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      reset();
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getTaxUsers();
  }, [apiCallStatus]);

  const columns = () => {
    return [
      {
        name: <span style={{ fontSize: "16px" }}>S.N.</span>,
        selector: (row) => row.sn,
        sortable: true,
        minWidth: "30px",
        compact: true,
      },
      {
        name: <span style={{ fontSize: "16px" }}>Action</span>,
        minWidth: "30px",
        cell: (params) => (
          <StatusButton
            id={params?.id}
            params={params}
            setApiCallStatus={setApiCallStatus}
          />
        ),
      },
      {
        name: <span style={{ fontSize: "16px" }}>Full Name</span>,
        selector: (row) => row.fullName,
        sortable: true,
        minWidth: "130px",
        compact: true,
      },
      {
        name: <span style={{ fontSize: "16px" }}>Email Address</span>,
        selector: (row) => row.email,
        sortable: true,
        minWidth: "130px",
        compact: true,
      },
      {
        name: <span style={{ fontSize: "16px" }}>Mobile No.</span>,
        selector: (row) => row.mobile,
        sortable: true,
        minWidth: "130px",
        compact: true,
      },
      {
        name: <span style={{ fontSize: "16px" }}>Company Name</span>,
        selector: (row) => row.company,
        sortable: true,
        minWidth: "150px",
        compact: true,
      },
      {
        name: <span style={{ fontSize: "16px" }}>Company Number</span>,
        selector: (row) => row.companyNumber,
        sortable: true,
        minWidth: "170px",
        compact: true,
      },
    ];
  };

  let rows = [];
  if (tableData?.length > 0) {
    rows = tableData?.map((item, i) => {
      return {
        id: item?.id,
        sn: i + 1,
        fullName: item?.full_name,
        email: item?.email,
        mobile: item?.mobile_no,
        company: item?.company_name,
        companyNumber: item?.company_mobile_no,
      };
    });
  }
  useEffect(() => {
    getTaxUsers();
  }, []);

  // Filtering Category based on category name
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <PageHeader1
          pagetitle="Tax Relief Users"
          modalbutton={() => {
            return (
              <div className="col-auto d-flex w-sm-100">
                {/* <button
                  type="button"
                  onClick={() => {
                    reset({ room: null }, { keepValues: false });
                    setIsmodal(true);
                    setValue("type", "add");
                  }}
                  className="btn btn-primary btn-set-task w-sm-100"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i>Add Category
                </button> */}
              </div>
            );
          }}
        />
        <div className="row clearfix g-3">
          <div className="col-sm-12 mt-3">
            <div className="card">
              <div className="card-body">
                <div
                  id="myProjectTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      {isLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "70vh" }}
                        >
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <DataTable
                          columns={columns()}
                          defaultSortField="title"
                          pagination
                          selectableRows={false}
                          className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                          highlightOnHover={true}
                          data={rows}
                          subHeader
                          paginationResetDefaultPage={resetPaginationToggle}
                          subHeaderComponent={subHeaderComponentMemo}
                          persistTableHead
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TaxReliefUsers;
